/* App.css */

/* Enlever le soulignement pour tous les liens */
a {
  text-decoration: none;
}

/* Ajouter un soulignement sur hover si nécessaire */
a:hover {
  text-decoration: underline; /* Optionnel, pour souligner les liens au survol */
}
